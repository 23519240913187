// import {createTheme, adaptV4Theme} from '@mui/styles'

import {createTheme} from '@mui/material/styles'

const defaultTheme = createTheme(
  // adaptV4Theme(
  {
    typography: {
      fontFamily: [
        'CamptonMedium',
        'CamptonLight',
        'CamptonSemiBold',
        'CamptonBook',
      ].join(','),
    },
    layout: {
      maxWidth: 1160,
      logo: {
        width: 154,
        height: 46,
        mobile: {
          width: 90,
          height: 20,
        },
      },
      header: {
        height: 100,
        heightMobile: 60,
        responsiveHeight: 116,
        modal: {
          top: 100,
          left: -100,
          leftTablet: -193,
          width: {
            medium: 300,
            large: 450,
          },
        },
        hamburger: {
          width: 16,
          height: 18,
        },
        navigation: {
          height: '20vh',
        },
        buttonClose: {
          height: 24,
          width: 24,
          left: 3,
          top: -4,
        },
        scrollable: {
          maxWidth: 267,
          width: '75vw!important',
        },
      },
      heading: {maxWidth: 560},
      footer: {
        logo: {
          width: 140,
          height: 42,
        },
      },
      form: {
        height: 665,
      },
      mainCarousel: {
        slideWrapper: {
          height: 560,
          maxWidth: 600,
        },
        slideRight: {
          maxWidth: 600,
          height: '30vw',
          maxHeight: 500,
        },
        image: {
          borderRadius: '120px 40px',
          borderRadiusMobile: '10px 40px',
          maxWidth: 500,
          maxHeight: 450,
        },
        slideNavigation: {
          size: 75,
        },
      },
      input: {
        maxHeight: 119,
      },
      instagram: {
        gridGap: 20,
        gridTemplateColumns: 'repeat(4, minmax(240px, 1fr))',
        gridTemplateColumnsMd: 'repeat(2, minmax(240px, 1fr))',
        gridTemplateColumnsXs: 'repeat(1, minmax(240px, 1fr))',
        gridTemplateRowsMd: 'repeat(2, 1fr)',
        gridTemplateRowsXs: 'repeat(4, 1fr)',
        cardMaxSizeMd: 220,
        cardMaxSizeXs: 200,
        gapMd: 20,
        gapXs: 40,
      },
      worldMap: {
        fixGutterBetweenTiles: '1024.5px !important',
        heightMd: 'calc(100vh - 130px)',
        heightSm: 'calc(120vw)',
        bigMaxSize: {maxHeight: 700, maxWidthMd: 1369, maxWidthLg: 1543},
        smallMaxSize: {maxHeight: 600, maxWidthMd: 1400, maxWidthLg: 1400},
      },
      homeInsights: {
        image: {
          maxWidth: 800,
          maxWidthMobile: 500,
        },
        text: {
          marginLeft: 63,
          minHeight: 360,
          width: 600,
        },
      },
      related: {
        width: 260,
        maxWidth: 540,
      },
      tags: {
        maxHeight: 19,
      },
      playbookPartnerHeader: {
        height: 'auto',
        imageContainer: {
          width: 200,
          height: 150,
        },
      },
      filterContainer: {
        maxWidth: 345,
      },
      keyplayHeader: {
        height: 521,
        width: 1280,
        maxWidth: 720,
        box: {
          height: 100,
          maxWidth: 1200,
        },
      },
      keyplayPartners: {
        container: {
          minHeight: 540,
        },
        image: {
          height: 216,
          width: 205,
        },
      },
      keyplayTestimonial: {
        maxWidth: 700,
      },
      socialShare: {
        button: {
          minHeight: 50,
          minWidth: 50,
        },
      },
      search: {
        input: {
          height: 50,
          width: '100%',
        },
        modal: {
          width: 1160,
          boxShadow: '0px 2px 20px 20px rgb(0 0 0 / 6%)',
        },
        noResults: {
          height: 100,
        },
        results: {
          height: 500,
          width: 1160,
          image: {
            height: 150,
            width: 250,
          },
        },
      },
      aboutUs: {
        wideCard: 960,
        brandImgSize: 160,
        infosminHeight: 160,
        managementCardWidth: 253,
        managementImage: 140,
        kpisIconsWidth: 270,
        kpisIconsHeight: 190,
      },
      popper: {
        width: 400,
        height: 200,
      },
    },
    fontSizes: {
      bigTitle: 42,
      title: 35,
      largeDescription: 25,
      description: 18,
      subTitle: 26,
      innerTitle: 22,
      tagButton: 12,
    },
    customZindex: {
      decorations: 8,
      decorationsKls: 0,
      instaFeedHover: 7,
      header: 1000000,
    },
    shadow: {
      default: '0px 2px 2px 0px rgba(0,0,0,0.35)',
      keys: '0 8px 16px 0 rgba(51, 51, 51, 0.1)',
      keyplayHeader: '0 32px 34px 0 rgba(51, 51, 51, 0.1)',
      large: '0px 2px 20px 20px rgb(0 0 0 / 6%)',
      markerShadow: '-2px 2px 3px 0px rgb(0 0 0 / 28%)',
      markerShadowHover: '-4px 4px 5px 0px rgb(0 0 0 / 28%)',
    },
    transition: {
      animation: (target = 'all') =>
        `${target} 0.3s cubic-bezier(.25,.8,.25,1)`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1440,
        xl: 1920,
      },
    },
    overrides: {
      MuiFab: {
        root: {
          backgroundColor: '#930053',
          color: '#ffffff',
          '&:focus': {
            backgroundColor: '#930053',
          },
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: '#930053',
          color: '#ffffff',
          borderRadius: 25,
          '&  svg': {
            marginRight: 4,
          },
          '&:focus': {
            backgroundColor: '#930053',
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.35)',
          color: '#333333',
          fontSize: 12,
          fontFamily: 'CamptonBook',
        },
      },
    },
  },
  // ),
)

export default defaultTheme
