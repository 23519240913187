import * as R from 'ramda'
import {bool, node, string} from 'prop-types'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import React, {forwardRef} from 'react'

import classnames from 'classnames'
import useStyles from './styles'

const Media = forwardRef(
  (
    {
      data,
      isRoundImage,
      alt,
      is169Ratio,
      className,
      key,
      isFullWidth,
      hasNoStyles,
      videoControls,
      videoAutoPlay,
      poster,
      hasNoBorderRadius,
      hasRoundCorners,
    },
    ref,
  ) => {
    const classes = useStyles()

    const file = R.pathOr(' ', ['file'], data)
    const fluid = R.pathOr(null, ['fluid'], data)
    const description = R.pathOr(' ', ['description'], data)
    const title = R.pathOr(' ', ['title'], data)
    const posterImage = R.pathOr(' ', ['file', 'url'], poster)

    const image = getImage(data)

    const getAlt = () => {
      if (alt) {
        return alt
      }
      if (R.length(R.trim(description)) > 0) {
        return description
      }
      if (R.length(R.trim(title)) > 0) {
        return title
      }

      return ''
    }

    return (
      <>
        {image && (
          <GatsbyImage
            key={key}
            image={image}
            alt={getAlt()}
            className={classnames(classes.image, className, {
              [classes.svgImage]: file.contentType === 'image/svg+xml',
              [classes.roundImage]: isRoundImage,
              [classes.aspectRatio169]: is169Ratio,
              [classes.noBorderRadius]: hasNoBorderRadius,
              [classes.hasRoundCorners]: hasRoundCorners,
            })}
          />
        )}

        {file && !fluid && !image && file.contentType === 'video/mp4' && (
          <video
            ref={ref}
            type={file.contentType}
            src={file.url}
            loop
            controls={videoControls}
            autoPlay={videoAutoPlay}
            playsInline
            className={classes.videoAsset}
            poster={posterImage}
          />
        )}
        {file && !fluid && !image && file.contentType === 'image/svg+xml' && (
          <img
            key={key}
            src={file.url}
            alt={getAlt()}
            className={classnames(className, {[classes.svgImage]: !className})}
            loading="lazy"
          />
        )}
        {file &&
          !fluid &&
          !image &&
          (file.contentType === 'image/jpeg' ||
            file.contentType === 'image/webp' ||
            file.contentType === 'image/png') && (
            <img
              key={key}
              src={file.url}
              alt={getAlt()}
              className={classnames(
                (isRoundImage && classes.roundImage) ||
                  (!hasNoStyles && classes.jpegOrPngImage) ||
                  null,
                is169Ratio && classes.aspectRatio169,
                isFullWidth && classes.isFullWidth,
                hasNoBorderRadius && classes.noBorderRadius,
              )}
              loading="lazy"
            />
          )}
      </>
    )
  },
)

Media.propTypes = {
  alt: string,
  data: node,
  is169Ratio: bool,
  isRoundImage: bool,
  videoAutoPlay: bool,
  videoControls: bool,
}
Media.defaultProps = {
  alt: '',
  data: null,
  is169Ratio: false,
  isRoundImage: false,
  videoAutoPlay: false,
  videoControls: true,
}

export default Media
